.card {
  width: 100%;
  background-color: #fff;
  border: none;
  margin-bottom: 20px;
  border-radius: 0.625rem;
  // min-height: 200px;
  // box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
  //   0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1),
  //   0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12),
  //   0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);

  box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12),
    0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);

  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
  }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
  }
  .list-group-item {
    padding: 0.8125rem 1.875rem;
  }
  .card-text {
    margin-bottom: 1.5625rem;
  }
  a:hover {
    text-decoration: none;
  }
  .card-small {
    // box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11),
    //   0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06),
    //   0 7px 70px rgba(90, 97, 105, 0.1);

    .card-small .card-header,
    .card-small .card-body,
    .card-small .card-footer {
      padding: 1rem 1rem;
    }
  }

  .card-body {
    padding: 1.875rem;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .card-header {
    padding: 1.09375rem 1.875rem;
    background-color: #fff;
    border-bottom: none;
    border-radius: 0.625rem 0.625rem 0 0;
    .card-header:first-child {
      border-radius: 0.625rem 0.625rem 0 0;
    }
  }

  .border {
    border: 1px solid #e1e5eb !important;
  }

  .border-top {
    border-top: 1px solid #e1e5eb !important;
  }

  .border-right {
    border-right: 1px solid #e1e5eb !important;
  }

  .border-bottom {
    border-bottom: 1px solid #e1e5eb !important;
  }

  .border-left {
    border-left: 1px solid #e1e5eb !important;
  }
}
